body {
    margin: 0;
    padding: 0;
}

#approot {
    display: flex;
    flex-flow: row wrap;
    width: 100vw;
    height: 100dvh;
}

#map {
    width: 100vw;
}

.map-container {
    display: inline;
    width: 100vw;
    height: 75dvh;
}

.mapboxgl-popup {
    max-width: 300px !important;
}

#tracker-marker {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
}

#tracker-popup table {
    border-spacing: 8px 0px;
    line-height: 2ex;
}

#weather-marker {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff
}

#weather-popup table {
    border-spacing: 8px 0px;
    line-height: 2ex;
}

.elevation-wrapper {
    display: inline;
    width: 100vw;
    height: 25dvh;
    /* background-color: #bda6; */
}

.prefs-menu {
    flex-direction: row;
    display: flex;
}

.prefs-menu .button-holder {
    display: flex;
}

.prefs-menu .prefs-menu-button {
    margin-top: auto;
}

.prefs-menu .prefs-menu-inner {
    display: none;
}

.mapboxgl-compact-show .prefs-menu-inner {
    display: block;
}

.prefs-menu-inner ul {
    margin: 0;
    padding: 4px;
}

.prefs-menu-inner ul lh {
    font-weight: bold;
}

.prefs-menu-inner ul li {
    margin: 2px;
    list-style-type: none;
    text-align: left;
}

/*
.prefs-menu-inner ul li label {
    display: block;
}
    */

.prefs-menu-inner ul li input[type="radio"] {
    display: none;
}

.prefs-menu-inner ul li input[type="radio"]:checked+label {
    background-color: #6cf3;
}

.prefs-menu-inner ul li:hover {
    background-color: #6cf6;
}